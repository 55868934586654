import React, { useEffect } from 'react';
import NavBar from './NavBar';
import { NavLink } from 'react-router-dom';


const PrivacyPolicy: React.FC = () => {
    const pages = {
        "privacy_policy": "Privacy Policy",
        "terms-and-conditions": "Terms And Conditions",
        "cookies-policy": "Cookies Policy",
        "faq": "FAQs",
        "support": "Support",
    };

    return (
        <div className="container-xxl bg-white p-0">
            <NavBar />
            <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-md-9 col-sm-12 ">

                        <h4 className="g-font-size-22--xs text-primary">Privacy Policy - iGiTREE (Updated 2021-04-06)</h4>

                        <p>
                            Welcome to iGiTREE, the leading platform for family tree building, information matching solutions, and ancestry tracking. At iGiTREE, we are committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy outlines how we collect, use, share, and protect your personal information when you use our website and services.
                        </p>
                        <p>
                            <strong>1. Who controls your personal data?</strong><br />
                            iGiTREE is the controller of your personal data. If you have any questions about this Privacy Policy or our practices, please contact us at <i>info@igitree.rw</i>.
                        </p>
                        <p>
                            <strong>2.  What personal data do we collect?</strong><br />
                            We collect various types of personal data to provide and improve our services. The types of personal data we may collect include:

                            <ol>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Contact information (phone number, address)</li>
                                <li>Year of birth</li>
                                <li>Family relationship information</li>
                                <li>User-generated content (family tree data, photos, documents)</li>
                                <li>Usage data (website activity, IP address, browser type)</li>

                            </ol>
                        </p>
                        <p>
                            <strong>3. For what purposes do we process personal data?</strong><br />
                            We process personal data for the following purposes:
                            <ol>
                                <li>To create and manage your account</li>
                                <li>To provide and personalize our services</li>
                                <li>To communicate with you and respond to your inquiries</li>
                                <li>To improve our website and services</li>
                                <li>To comply with legal obligations</li>
                            </ol>
                        </p>
                        <p>
                            <strong>4. With whom do we share personal data?</strong><br />
                            We may share your personal data with the following parties:
                            <ol>
                                <li>Service providers who assist us in providing our services</li>
                                <li>Legal authorities when required by law or to protect our rights</li>
                                <li>Third parties with your consent</li>
                            </ol>
                        </p>
                        <p>
                            <strong>5. Where do we store personal data?</strong><br />
                            Your personal data may be stored and processed in various locations, including servers located within and outside your country of residence. By using our services, you consent to the transfer of your personal data to these locations.
                        </p>
                        <p>
                            <strong>6. How do we secure personal data?</strong><br />
                            We employ industry-standard security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security audits.
                        </p>
                        <p>
                            <strong>7. How long do we retain personal data?</strong><br />
                            We retain personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When personal data is no longer needed, we securely delete or anonymize it.
                        </p>
                        <p>
                            <strong>8. How can you access and correct your personal data?</strong><br />
                            You have the right to access, correct, or delete your personal data at any time. You can do so by logging into your account or contacting us directly. We will respond to your requests promptly and in accordance with applicable laws.
                        </p>
                        <p>
                            <strong>9. Effective date and amendments.</strong><br />
                            This Privacy Policy is effective as of the date indicated above and may be amended from time to time. Any updates will be posted on this page, and we encourage you to review this Privacy Policy periodically for changes.<br />

                            If you have any questions or concerns about our Privacy Policy or practices, please contact us at <i>info@igitree.rw</i>.<br />

                            Thank you for trusting iGiTREE with your personal information.<br />
                        </p>
                        
                    </div>
                    <div className="col-md-3 col-sm-12">

                        <div className="row mb-2">
    <div className="col-md-12 col-sm-12"></div>
</div>

<div className="row mb-2">
    <div className="col-md-12 col-sm-12">
        <ul className="list-group" style={{ listStyleType: 'none', padding: 0 }}>
            <div className="list-group list-group-flush">
                {Object.entries(pages).map(([url, title]) => (
                    <li key={url}>
                        <NavLink 
                            to={`/${url}`} 
                            className="list-group-item"
                            style={({ isActive }) => ({
                                fontWeight: isActive ? 'bold' : 'normal',
                                color: isActive ? 'blue' : 'inherit',
                            })}
                        >
                            {title}
                        </NavLink>
                    </li>
                ))}
            </div>
        </ul>
    </div>
</div>


                    </div>
                </div>
            </div>
        </div>
        </div>
                );
            }
export default PrivacyPolicy;
